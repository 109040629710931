import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
/* URW Type Foundry - NeuzeitGro-Reg.woff */
@font-face {
    font-family: "Neuzeit Grotesk";
    src: url('https://cdn.builder.io/o/assets%2Fe9ed5fb489ab4b769611bc610a472a56%2Fa4d45057ac914a46bba9bf750204b8d4?alt=media&token=f9c65aa9-b643-44f2-81cc-b89d37c91b63&apiKey=${process.env.GATSBY_BUILDER_API_KEY}') format('woff2');
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Neuzeit Grotesk - Bold";
    src: url('https://cdn.builder.io/o/assets%2Fe9ed5fb489ab4b769611bc610a472a56%2Fe7f4360c4ffb4f8892cf31218d453d7f?alt=media&token=bb1dbb07-36bb-4918-a9b6-ab0f697b16cf&apiKey=${process.env.GATSBY_BUILDER_API_KEY}') format('woff2');
    font-display: swap;
    font-weight: 400;
    font-style: normal;
}

@font-face {
  font-family: "P22 Underground";
  src: local("P22 Underground"), url('https://cdn.builder.io/o/assets%2Fe9ed5fb489ab4b769611bc610a472a56%2Fc4860f95263d45f7af87f1b9b47627c1?alt=media&token=e56d3ad7-3772-45dc-a740-8d1f876f67d5&apiKey=${process.env.GATSBY_BUILDER_API_KEY}') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "P22 Underground";
  src:local("P22 Underground"), url('https://cdn.builder.io/o/assets%2Fe9ed5fb489ab4b769611bc610a472a56%2Fe7d9fd3b92ab4d389c4bac77e9f2e5c7?alt=media&token=0590264b-afba-4a1a-8ee1-1979441b6cc3&apiKey=${process.env.GATSBY_BUILDER_API_KEY}') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "P22 Underground";
  src: local("P22 Underground"), url('https://cdn.builder.io/o/assets%2Fe9ed5fb489ab4b769611bc610a472a56%2F166d74fd23054d098527fd7ac45ef987?alt=media&token=fb26fbf9-ff6f-4561-a2c8-41a02ad4059e&apiKey=${process.env.GATSBY_BUILDER_API_KEY}') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 900;
}

@font-face {
    font-family: 'Utopia Std';
    src: local("Utopia Std"), url('https://cdn.builder.io/o/assets%2Fe9ed5fb489ab4b769611bc610a472a56%2F732fa2d4bc07461d93bbca077b5cf450?alt=media&token=e94e1f89-ba2e-4268-bc5b-f3e7f26e7e45&apiKey=${process.env.GATSBY_BUILDER_API_KEY}') format('woff2');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Utopia Std';
    src: local("Utopia Std"), url('https://cdn.builder.io/o/assets%2Fe9ed5fb489ab4b769611bc610a472a56%2Ffcb0452a62924a8b85d0bbba8b7c71ae?alt=media&token=a700409a-49ac-4216-ba47-a30931fe63ad&apiKey=${process.env.GATSBY_BUILDER_API_KEY}') format('woff2');
    font-display: swap;
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Utopia Std";
    src: local("Utopia Std"), url('https://cdn.builder.io/o/assets%2Fe9ed5fb489ab4b769611bc610a472a56%2F69fcbab0600d4881956831e385fe92af?alt=media&token=084aae26-a4d1-4e5c-9e60-ca9a868cf667&apiKey=${process.env.GATSBY_BUILDER_API_KEY}') format('woff2');
    font-display: swap;
    font-weight: bold;
    font-style: normal;
}
  `;

export default GlobalStyle;
